/*
 * Copyright 2012-2020 Online-Go.com
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *  http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ValidSound } from './sfx';

export interface SpritePack {
    pack_id: string;
    language: string;
    country: string;
    name: string;
    filename_prefix: string;
    definitions: {
        [id in ValidSound]?: [
            number, /* start offset (ms) */
            number  /* duration (ms) */
        ];
    };
}

export let sprite_packs:{
    [pack_id:string]: SpritePack
} = {};


sprite_packs["de-de-lara-numbers"] = {
    pack_id: "de-de-lara-numbers",
    language: "de",
    country: "de",
    name: "Lara",
    filename_prefix: "de-de-lara-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        487.5
    ],
    "1": [
        2487.5,
        847.5
    ],
    "10": [
        4335.0,
        517.8541666666666
    ],
    "11": [
        5852.854166666667,
        426.54166666666663
    ],
    "12": [
        7279.395833333333,
        596.6666666666666
    ],
    "13": [
        8876.0625,
        749.8125
    ],
    "14": [
        10625.875,
        806.4791666666666
    ],
    "15": [
        12432.354166666666,
        830.2708333333333
    ],
    "16": [
        14262.625,
        910.9791666666666
    ],
    "17": [
        16173.604166666668,
        808.6458333333333
    ],
    "18": [
        17982.25,
        1028.4583333333333
    ],
    "19": [
        20010.708333333332,
        823.1458333333334
    ],
    "2": [
        21833.854166666664,
        656.9166666666667
    ],
    "20": [
        23490.770833333332,
        825.5416666666666
    ],
    "21": [
        25316.3125,
        1068.8958333333335
    ],
    "22": [
        27385.208333333336,
        1228.5625
    ],
    "23": [
        29613.770833333332,
        1188.375
    ],
    "24": [
        31802.145833333336,
        1226.3333333333333
    ],
    "25": [
        34028.479166666664,
        1287.1666666666665
    ],
    "26": [
        36315.645833333336,
        1268.5625
    ],
    "27": [
        38584.208333333336,
        1474.9375
    ],
    "28": [
        41059.14583333333,
        1131.4166666666667
    ],
    "29": [
        43190.5625,
        1239.4166666666665
    ],
    "3": [
        45429.97916666667,
        550.6666666666666
    ],
    "30": [
        46980.645833333336,
        724.2083333333334
    ],
    "31": [
        48704.854166666664,
        1134.1666666666667
    ],
    "32": [
        50839.020833333336,
        1398.3958333333333
    ],
    "33": [
        53237.41666666667,
        1088.0416666666665
    ],
    "34": [
        55325.45833333333,
        1226.5
    ],
    "35": [
        57551.95833333333,
        1187.0625
    ],
    "36": [
        59739.020833333336,
        1228.4375
    ],
    "37": [
        61967.458333333336,
        1250.4166666666667
    ],
    "38": [
        64217.87500000001,
        1104.9166666666667
    ],
    "39": [
        66322.79166666666,
        1139.125
    ],
    "4": [
        68461.91666666667,
        575.5
    ],
    "40": [
        70037.41666666667,
        710.3541666666666
    ],
    "41": [
        71747.77083333333,
        1028.7083333333333
    ],
    "42": [
        73776.47916666666,
        1343.7291666666667
    ],
    "43": [
        76120.20833333334,
        1151.0833333333333
    ],
    "44": [
        78271.29166666667,
        1128.75
    ],
    "45": [
        80400.04166666667,
        1330.2083333333335
    ],
    "46": [
        82730.25,
        1441.5416666666667
    ],
    "47": [
        85171.79166666666,
        1242.1666666666667
    ],
    "48": [
        87413.95833333333,
        1146.7708333333333
    ],
    "49": [
        89560.72916666666,
        1163.9791666666665
    ],
    "5": [
        91724.70833333334,
        659.7291666666667
    ],
    "50": [
        93384.4375,
        866.0
    ],
    "51": [
        95250.4375,
        1179.4583333333333
    ],
    "52": [
        97429.89583333333,
        1313.8541666666667
    ],
    "53": [
        99743.75,
        1273.4791666666667
    ],
    "54": [
        102017.22916666667,
        1267.3958333333333
    ],
    "55": [
        104284.625,
        1339.3333333333333
    ],
    "56": [
        106623.95833333333,
        1426.4166666666667
    ],
    "57": [
        109050.375,
        1307.25
    ],
    "58": [
        111357.625,
        1182.6666666666667
    ],
    "59": [
        113540.29166666666,
        1239.9791666666667
    ],
    "6": [
        115780.27083333333,
        628.9375
    ],
    "60": [
        117409.20833333334,
        757.625
    ],
    "7": [
        119166.83333333333,
        585.6875
    ],
    "8": [
        120752.52083333333,
        570.1875
    ],
    "9": [
        122322.70833333334,
        535.3333333333334
    ]
}
};

sprite_packs["de-de-lara-phrases"] = {
    pack_id: "de-de-lara-phrases",
    language: "de",
    country: "de",
    name: "Lara",
    filename_prefix: "de-de-lara-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1529.75
    ],
    "3_periods_left": [
        3529.75,
        1465.3958333333333
    ],
    "4_periods_left": [
        5995.145833333333,
        1457.4791666666667
    ],
    "5_periods_left": [
        8452.625,
        1392.3958333333333
    ],
    "black_wins": [
        10845.020833333332,
        1009.9166666666666
    ],
    "byoyomi": [
        12854.9375,
        724.4166666666667
    ],
    "challenge_received": [
        14579.354166666666,
        1443.6041666666665
    ],
    "disconnected": [
        17022.958333333332,
        573.9375
    ],
    "game_paused": [
        18596.895833333336,
        1151.6458333333333
    ],
    "game_resumed": [
        20748.541666666668,
        1324.6666666666667
    ],
    "game_started": [
        23073.208333333332,
        1269.625
    ],
    "last_period": [
        25342.833333333336,
        1366.4791666666667
    ],
    "overtime": [
        27709.3125,
        1099.2916666666665
    ],
    "pass": [
        29808.604166666664,
        526.8541666666666
    ],
    "period": [
        31335.458333333332,
        758.4166666666666
    ],
    "player_disconnected": [
        33093.875,
        1100.0
    ],
    "player_reconnected": [
        35193.875,
        1575.0625
    ],
    "reconnected": [
        37768.9375,
        1040.875
    ],
    "remove_the_dead_stones": [
        39809.8125,
        1500.9166666666667
    ],
    "review_started": [
        42310.72916666667,
        1876.4583333333333
    ],
    "tie": [
        45187.1875,
        824.2291666666666
    ],
    "undo_granted": [
        47011.41666666667,
        1221.8958333333335
    ],
    "undo_requested": [
        49233.3125,
        1497.7916666666667
    ],
    "white_wins": [
        51731.10416666667,
        975.75
    ],
    "you_have_lost": [
        53706.854166666664,
        633.4791666666667
    ],
    "you_have_won": [
        55340.333333333336,
        678.375
    ],
    "your_move": [
        57018.708333333336,
        746.0
    ],
    "your_opponent_has_disconnected": [
        58764.70833333333,
        2185.375
    ],
    "your_opponent_has_reconnected": [
        61950.08333333333,
        1817.0625
    ]
}
};

sprite_packs["en-gb-claire-numbers"] = {
    pack_id: "en-gb-claire-numbers",
    language: "en",
    country: "gb",
    name: "Claire",
    filename_prefix: "en-gb-claire-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        489.625
    ],
    "1": [
        2489.625,
        359.1458333333333
    ],
    "10": [
        3848.7708333333335,
        364.20833333333337
    ],
    "11": [
        5212.979166666667,
        533.2916666666667
    ],
    "12": [
        6746.270833333333,
        556.4791666666666
    ],
    "13": [
        8302.75,
        587.4791666666666
    ],
    "14": [
        9890.229166666666,
        662.7083333333334
    ],
    "15": [
        11552.9375,
        688.4791666666666
    ],
    "16": [
        13241.416666666666,
        762.0416666666666
    ],
    "17": [
        15003.458333333332,
        750.0
    ],
    "18": [
        16753.458333333336,
        597.1666666666666
    ],
    "19": [
        18350.625,
        654.2291666666666
    ],
    "2": [
        20004.854166666668,
        390.125
    ],
    "20": [
        21394.979166666664,
        459.1458333333333
    ],
    "21": [
        22854.125,
        650.0
    ],
    "22": [
        24504.125,
        658.0416666666666
    ],
    "23": [
        26162.166666666668,
        688.7916666666667
    ],
    "24": [
        27850.958333333336,
        728.1875
    ],
    "25": [
        29579.145833333332,
        983.3958333333334
    ],
    "26": [
        31562.541666666668,
        759.5833333333334
    ],
    "27": [
        33322.125,
        732.5208333333333
    ],
    "28": [
        35054.64583333333,
        690.6875
    ],
    "29": [
        36745.333333333336,
        731.6666666666667
    ],
    "3": [
        38477.0,
        392.4375
    ],
    "30": [
        39869.4375,
        508.12500000000006
    ],
    "31": [
        41377.5625,
        681.8333333333333
    ],
    "32": [
        43059.395833333336,
        592.3541666666666
    ],
    "33": [
        44651.75,
        662.5833333333333
    ],
    "34": [
        46314.33333333333,
        869.7916666666666
    ],
    "35": [
        48184.125,
        785.8541666666666
    ],
    "36": [
        49969.97916666667,
        760.0
    ],
    "37": [
        51729.979166666664,
        900.0
    ],
    "38": [
        53629.979166666664,
        755.75
    ],
    "39": [
        55385.729166666664,
        736.0625
    ],
    "4": [
        57121.791666666664,
        498.2083333333333
    ],
    "40": [
        58620.0,
        529.0416666666666
    ],
    "41": [
        60149.04166666667,
        678.0208333333333
    ],
    "42": [
        61827.0625,
        711.1875
    ],
    "43": [
        63538.25,
        781.2708333333334
    ],
    "44": [
        65319.52083333333,
        800.0
    ],
    "45": [
        67119.52083333334,
        936.3333333333334
    ],
    "46": [
        69055.85416666666,
        866.4166666666666
    ],
    "47": [
        70922.27083333333,
        885.25
    ],
    "48": [
        72807.52083333333,
        959.1875
    ],
    "49": [
        74766.70833333333,
        900.0
    ],
    "5": [
        76666.70833333333,
        536.8958333333334
    ],
    "50": [
        78203.60416666667,
        621.0625
    ],
    "51": [
        79824.66666666667,
        912.4375
    ],
    "52": [
        81737.10416666667,
        973.0833333333333
    ],
    "53": [
        83710.1875,
        925.0416666666666
    ],
    "54": [
        85635.22916666666,
        915.875
    ],
    "55": [
        87551.10416666666,
        983.1458333333334
    ],
    "56": [
        89534.25,
        878.0
    ],
    "57": [
        91412.25,
        810.9375
    ],
    "58": [
        93223.1875,
        791.5625
    ],
    "59": [
        95014.75,
        885.625
    ],
    "6": [
        96900.375,
        511.75000000000006
    ],
    "60": [
        98412.125,
        700.0
    ],
    "7": [
        100112.125,
        495.6875
    ],
    "8": [
        101607.8125,
        367.25
    ],
    "9": [
        102975.0625,
        457.6666666666667
    ]
}
};

sprite_packs["en-gb-claire-phrases"] = {
    pack_id: "en-gb-claire-phrases",
    language: "en",
    country: "gb",
    name: "Claire",
    filename_prefix: "en-gb-claire-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1594.5208333333335
    ],
    "3_periods_left": [
        3594.520833333333,
        1486.0
    ],
    "4_periods_left": [
        6080.520833333333,
        1468.9166666666667
    ],
    "5_periods_left": [
        8549.4375,
        1538.3958333333335
    ],
    "black_wins": [
        11087.833333333334,
        910.1875
    ],
    "byoyomi": [
        12998.020833333334,
        826.9791666666667
    ],
    "challenge_received": [
        14825.0,
        1121.8958333333333
    ],
    "disconnected": [
        16946.895833333332,
        882.6458333333334
    ],
    "game_paused": [
        18829.541666666668,
        960.4583333333333
    ],
    "game_resumed": [
        20790.0,
        1050.5625
    ],
    "game_started": [
        22840.5625,
        1036.8333333333333
    ],
    "last_period": [
        24877.395833333336,
        1031.9166666666665
    ],
    "overtime": [
        26909.3125,
        731.125
    ],
    "pass": [
        28640.4375,
        534.3125
    ],
    "period": [
        30174.75,
        483.2916666666667
    ],
    "player_disconnected": [
        31658.041666666664,
        1298.0
    ],
    "player_reconnected": [
        33956.041666666664,
        1283.2083333333333
    ],
    "reconnected": [
        36239.25,
        810.9375
    ],
    "remove_the_dead_stones": [
        38050.1875,
        1509.25
    ],
    "review_started": [
        40559.4375,
        1143.2083333333335
    ],
    "tie": [
        42702.645833333336,
        452.125
    ],
    "undo_granted": [
        44154.77083333333,
        1044.7083333333333
    ],
    "undo_requested": [
        46199.47916666667,
        1239.1666666666667
    ],
    "white_wins": [
        48438.645833333336,
        1033.3541666666665
    ],
    "you_have_lost": [
        50472.0,
        946.5
    ],
    "you_have_won": [
        52418.5,
        834.5416666666666
    ],
    "your_move": [
        54253.04166666667,
        779.1041666666667
    ],
    "your_opponent_has_disconnected": [
        56032.14583333333,
        2028.1875
    ],
    "your_opponent_has_reconnected": [
        59060.333333333336,
        1957.8958333333335
    ]
}
};

sprite_packs["en-us-amy-numbers"] = {
    pack_id: "en-us-amy-numbers",
    language: "en",
    country: "us",
    name: "Amy",
    filename_prefix: "en-us-amy-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "1": [
        1000.0,
        521.5416666666666
    ],
    "10": [
        2521.541666666667,
        580.0
    ],
    "2": [
        4101.541666666667,
        461.10416666666663
    ],
    "3": [
        5562.645833333334,
        530.1875
    ],
    "4": [
        7092.833333333333,
        692.5
    ],
    "5": [
        8785.333333333334,
        674.1666666666667
    ],
    "6": [
        10459.5,
        811.375
    ],
    "7": [
        12270.875,
        676.25
    ],
    "8": [
        13947.125,
        356.4583333333333
    ],
    "9": [
        15303.583333333334,
        731.4375
    ]
}
};

sprite_packs["en-us-lindsay-numbers"] = {
    pack_id: "en-us-lindsay-numbers",
    language: "en",
    country: "us",
    name: "Lindsay",
    filename_prefix: "en-us-lindsay-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        573.3333333333334
    ],
    "1": [
        2573.333333333333,
        437.7291666666667
    ],
    "10": [
        4011.0625000000005,
        476.25
    ],
    "11": [
        5487.3125,
        579.0208333333334
    ],
    "12": [
        7066.333333333334,
        570.75
    ],
    "13": [
        8637.083333333332,
        773.2708333333334
    ],
    "14": [
        10410.354166666666,
        767.4166666666666
    ],
    "15": [
        12177.770833333334,
        732.9166666666666
    ],
    "16": [
        13910.6875,
        779.2291666666666
    ],
    "17": [
        15689.916666666666,
        864.1458333333333
    ],
    "18": [
        17554.0625,
        701.0625
    ],
    "19": [
        19255.125,
        784.2708333333334
    ],
    "2": [
        21039.395833333332,
        451.8333333333333
    ],
    "20": [
        22491.229166666668,
        578.5208333333334
    ],
    "21": [
        24069.75,
        756.0208333333334
    ],
    "22": [
        25825.770833333332,
        748.6666666666667
    ],
    "23": [
        27574.4375,
        788.0
    ],
    "24": [
        29362.4375,
        769.8333333333334
    ],
    "25": [
        31132.270833333332,
        834.2083333333334
    ],
    "26": [
        32966.479166666664,
        863.6875
    ],
    "27": [
        34830.166666666664,
        826.25
    ],
    "28": [
        36656.416666666664,
        829.4375
    ],
    "29": [
        38485.85416666667,
        825.4166666666667
    ],
    "3": [
        40311.27083333333,
        499.5625
    ],
    "30": [
        41810.833333333336,
        550.7916666666666
    ],
    "31": [
        43361.625,
        759.2916666666667
    ],
    "32": [
        45120.916666666664,
        750.3125
    ],
    "33": [
        46871.229166666664,
        822.4583333333333
    ],
    "34": [
        48693.6875,
        858.8541666666667
    ],
    "35": [
        50552.54166666667,
        871.0416666666667
    ],
    "36": [
        52423.583333333336,
        956.5625
    ],
    "37": [
        54380.14583333333,
        883.4791666666667
    ],
    "38": [
        56263.625,
        893.9791666666666
    ],
    "39": [
        58157.604166666664,
        814.7916666666667
    ],
    "4": [
        59972.39583333333,
        568.0
    ],
    "40": [
        61540.395833333336,
        554.6458333333333
    ],
    "41": [
        63095.041666666664,
        780.6041666666666
    ],
    "42": [
        64875.645833333336,
        795.4583333333334
    ],
    "43": [
        66671.10416666667,
        810.7291666666666
    ],
    "44": [
        68481.83333333333,
        764.4583333333334
    ],
    "45": [
        70246.29166666667,
        885.7291666666666
    ],
    "46": [
        72132.02083333333,
        912.75
    ],
    "47": [
        74044.77083333333,
        876.125
    ],
    "48": [
        75920.89583333333,
        875.0416666666667
    ],
    "49": [
        77795.9375,
        842.3958333333334
    ],
    "5": [
        79638.33333333333,
        612.7916666666666
    ],
    "50": [
        81251.125,
        589.9375
    ],
    "51": [
        82841.0625,
        760.1875
    ],
    "52": [
        84601.25,
        780.6666666666666
    ],
    "53": [
        86381.91666666667,
        905.9166666666667
    ],
    "54": [
        88287.83333333334,
        955.6041666666667
    ],
    "55": [
        90243.4375,
        975.9375
    ],
    "56": [
        92219.375,
        976.8958333333333
    ],
    "57": [
        94196.27083333333,
        874.25
    ],
    "58": [
        96070.52083333333,
        828.2291666666666
    ],
    "59": [
        97898.75,
        826.4583333333333
    ],
    "6": [
        99725.20833333333,
        686.125
    ],
    "60": [
        101411.33333333333,
        662.0
    ],
    "7": [
        103073.33333333334,
        612.4166666666667
    ],
    "8": [
        104685.75,
        497.0833333333333
    ],
    "9": [
        106182.83333333333,
        554.2708333333334
    ]
}
};

sprite_packs["en-us-lindsay-phrases"] = {
    pack_id: "en-us-lindsay-phrases",
    language: "en",
    country: "us",
    name: "Lindsay",
    filename_prefix: "en-us-lindsay-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1531.5833333333333
    ],
    "3_periods_left": [
        3531.5833333333335,
        1673.5625
    ],
    "4_periods_left": [
        6205.145833333333,
        1548.3333333333333
    ],
    "5_periods_left": [
        8753.479166666666,
        1500.0
    ],
    "black_wins": [
        11253.479166666666,
        896.8541666666666
    ],
    "byoyomi": [
        13150.333333333334,
        635.4375
    ],
    "challenge_received": [
        14785.770833333334,
        1264.0625
    ],
    "disconnected": [
        17049.833333333332,
        898.0833333333334
    ],
    "game_paused": [
        18947.916666666668,
        966.875
    ],
    "game_resumed": [
        20914.791666666664,
        903.2291666666666
    ],
    "game_started": [
        22818.020833333332,
        903.8541666666666
    ],
    "last_period": [
        24721.875,
        1254.0208333333333
    ],
    "overtime": [
        26975.895833333332,
        748.4583333333334
    ],
    "pass": [
        28724.354166666668,
        517.0625
    ],
    "period": [
        30241.416666666664,
        530.6041666666666
    ],
    "player_disconnected": [
        31772.020833333332,
        1245.4166666666665
    ],
    "player_reconnected": [
        34017.4375,
        1149.875
    ],
    "reconnected": [
        36167.3125,
        779.8541666666666
    ],
    "remove_the_dead_stones": [
        37947.166666666664,
        1543.9791666666667
    ],
    "review_started": [
        40491.145833333336,
        1080.6875
    ],
    "tie": [
        42571.83333333333,
        484.3125
    ],
    "undo_granted": [
        44056.14583333333,
        1018.9583333333334
    ],
    "undo_requested": [
        46075.10416666667,
        1198.1041666666667
    ],
    "white_wins": [
        48273.208333333336,
        878.3541666666666
    ],
    "you_have_lost": [
        50151.5625,
        1127.2291666666667
    ],
    "you_have_won": [
        52278.791666666664,
        897.9375
    ],
    "your_move": [
        54176.72916666667,
        753.4791666666667
    ],
    "your_opponent_has_disconnected": [
        55930.208333333336,
        1662.1666666666665
    ],
    "your_opponent_has_reconnected": [
        58592.375,
        1629.0833333333335
    ]
}
};

sprite_packs["es-ar-sabrina-numbers"] = {
    pack_id: "es-ar-sabrina-numbers",
    language: "es",
    country: "ar",
    name: "Sabrina  ",
    filename_prefix: "es-ar-sabrina-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        613.7291666666666
    ],
    "1": [
        2613.729166666667,
        554.1666666666667
    ],
    "10": [
        4167.895833333334,
        689.2083333333333
    ],
    "11": [
        5857.104166666667,
        683.4375
    ],
    "12": [
        7540.541666666667,
        753.5625
    ],
    "13": [
        9294.104166666668,
        668.875
    ],
    "14": [
        10962.979166666666,
        809.3333333333334
    ],
    "15": [
        12772.3125,
        664.4375
    ],
    "16": [
        14436.75,
        950.2916666666666
    ],
    "17": [
        16387.041666666664,
        1011.4791666666667
    ],
    "18": [
        18398.520833333332,
        1017.2083333333333
    ],
    "19": [
        20415.729166666664,
        1061.3333333333333
    ],
    "2": [
        22477.0625,
        711.8541666666666
    ],
    "20": [
        24188.916666666668,
        758.4583333333334
    ],
    "21": [
        25947.375,
        1037.9375
    ],
    "22": [
        27985.3125,
        1030.9166666666667
    ],
    "23": [
        30016.229166666664,
        987.8541666666667
    ],
    "24": [
        32004.083333333332,
        1043.1875
    ],
    "25": [
        34047.270833333336,
        1111.5625
    ],
    "26": [
        36158.833333333336,
        997.5208333333333
    ],
    "27": [
        38156.354166666664,
        1173.4375
    ],
    "28": [
        40329.791666666664,
        1111.0833333333335
    ],
    "29": [
        42440.875,
        1132.1666666666667
    ],
    "3": [
        44573.04166666667,
        635.4791666666667
    ],
    "30": [
        46208.52083333333,
        779.9583333333333
    ],
    "31": [
        47988.479166666664,
        1043.3333333333333
    ],
    "32": [
        50031.8125,
        989.8958333333333
    ],
    "33": [
        52021.708333333336,
        982.5833333333334
    ],
    "34": [
        54004.291666666664,
        1055.7916666666667
    ],
    "35": [
        56060.08333333333,
        1168.6666666666667
    ],
    "36": [
        58228.75,
        1078.1666666666667
    ],
    "37": [
        60306.916666666664,
        1141.2291666666667
    ],
    "38": [
        62448.145833333336,
        1109.4375
    ],
    "39": [
        64557.58333333333,
        1122.5416666666667
    ],
    "4": [
        66680.125,
        659.3333333333334
    ],
    "40": [
        68339.45833333334,
        902.7708333333333
    ],
    "41": [
        70242.22916666666,
        1146.2916666666667
    ],
    "42": [
        72388.52083333333,
        1143.0416666666667
    ],
    "43": [
        74531.5625,
        1091.8541666666665
    ],
    "44": [
        76623.41666666667,
        1220.8125
    ],
    "45": [
        78844.22916666667,
        1227.6041666666665
    ],
    "46": [
        81071.83333333333,
        1202.125
    ],
    "47": [
        83273.95833333334,
        1241.2083333333333
    ],
    "48": [
        85515.16666666667,
        1161.5833333333335
    ],
    "49": [
        87676.75,
        1243.125
    ],
    "5": [
        89919.875,
        801.875
    ],
    "50": [
        91721.75,
        1085.6875
    ],
    "51": [
        93807.4375,
        1247.375
    ],
    "52": [
        96054.8125,
        1320.8958333333335
    ],
    "53": [
        98375.70833333333,
        1256.6875
    ],
    "54": [
        100632.39583333333,
        1306.4166666666665
    ],
    "55": [
        102938.8125,
        1366.25
    ],
    "56": [
        105305.0625,
        1298.0625
    ],
    "57": [
        107603.125,
        1368.7291666666667
    ],
    "58": [
        109971.85416666666,
        1349.375
    ],
    "59": [
        112321.22916666667,
        1472.5208333333333
    ],
    "6": [
        114793.75,
        753.7291666666666
    ],
    "60": [
        116547.47916666666,
        1107.4583333333335
    ],
    "7": [
        118654.9375,
        866.8333333333334
    ],
    "8": [
        120521.77083333333,
        657.6458333333334
    ],
    "9": [
        122179.41666666667,
        776.375
    ]
}
};

sprite_packs["es-ar-sabrina-phrases"] = {
    pack_id: "es-ar-sabrina-phrases",
    language: "es",
    country: "ar",
    name: "Sabrina",
    filename_prefix: "es-ar-sabrina-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1753.2291666666665
    ],
    "3_periods_left": [
        3753.229166666667,
        1763.4166666666667
    ],
    "4_periods_left": [
        6516.645833333333,
        1834.9583333333333
    ],
    "5_periods_left": [
        9351.604166666666,
        1834.25
    ],
    "black_wins": [
        12185.854166666668,
        1514.9166666666667
    ],
    "byoyomi": [
        14700.770833333334,
        929.3541666666667
    ],
    "challenge_received": [
        16630.125,
        1254.7291666666667
    ],
    "disconnected": [
        18884.854166666668,
        1205.1875
    ],
    "game_paused": [
        21090.041666666668,
        1293.5416666666665
    ],
    "game_resumed": [
        23383.583333333336,
        1808.0416666666667
    ],
    "game_started": [
        26191.625,
        1658.9375
    ],
    "last_period": [
        28850.5625,
        1307.8541666666667
    ],
    "overtime": [
        31158.416666666668,
        1374.3958333333333
    ],
    "pass": [
        33532.8125,
        573.5833333333334
    ],
    "period": [
        35106.39583333333,
        773.3541666666666
    ],
    "player_disconnected": [
        36879.75,
        1537.25
    ],
    "player_reconnected": [
        39417.0,
        1610.0
    ],
    "reconnected": [
        42027.0,
        1273.375
    ],
    "remove_the_dead_stones": [
        44300.375,
        2034.8125000000002
    ],
    "review_started": [
        47335.1875,
        1595.4375
    ],
    "tie": [
        49930.625,
        770.7708333333333
    ],
    "undo_granted": [
        51701.395833333336,
        1677.4791666666667
    ],
    "undo_requested": [
        54378.875,
        1545.3541666666665
    ],
    "white_wins": [
        56924.22916666667,
        1486.2708333333335
    ],
    "you_have_lost": [
        59410.5,
        921.125
    ],
    "you_have_won": [
        61331.625,
        1094.8958333333335
    ],
    "your_move": [
        63426.520833333336,
        1080.0
    ],
    "your_opponent_has_disconnected": [
        65506.52083333334,
        2202.8541666666665
    ],
    "your_opponent_has_reconnected": [
        68709.375,
        2286.5625
    ]
}
};

sprite_packs["fr-fr-chloe-numbers"] = {
    pack_id: "fr-fr-chloe-numbers",
    language: "fr",
    country: "fr",
    name: "Chloé",
    filename_prefix: "fr-fr-chloe-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        571.2916666666666
    ],
    "1": [
        2571.291666666667,
        265.1666666666667
    ],
    "10": [
        3836.4583333333335,
        577.0625
    ],
    "11": [
        5413.520833333333,
        475.08333333333337
    ],
    "12": [
        6888.604166666667,
        538.5
    ],
    "13": [
        8427.104166666666,
        502.58333333333337
    ],
    "14": [
        9929.6875,
        572.8333333333333
    ],
    "15": [
        11502.520833333334,
        432.0
    ],
    "16": [
        12934.520833333334,
        613.6875
    ],
    "17": [
        14548.208333333334,
        666.625
    ],
    "18": [
        16214.833333333334,
        736.0
    ],
    "19": [
        17950.833333333332,
        738.6458333333334
    ],
    "2": [
        19689.479166666668,
        414.9791666666667
    ],
    "20": [
        21104.458333333332,
        411.3125
    ],
    "21": [
        22515.770833333336,
        593.1875
    ],
    "22": [
        24108.958333333332,
        531.8958333333334
    ],
    "23": [
        25640.854166666668,
        676.1875
    ],
    "24": [
        27317.041666666668,
        867.3958333333334
    ],
    "25": [
        29184.4375,
        698.1458333333334
    ],
    "26": [
        30882.583333333332,
        762.5833333333333
    ],
    "27": [
        32645.166666666668,
        685.3333333333334
    ],
    "28": [
        34330.5,
        727.0833333333333
    ],
    "29": [
        36057.583333333336,
        778.2291666666666
    ],
    "3": [
        37835.8125,
        388.77083333333337
    ],
    "30": [
        39224.583333333336,
        458.0833333333333
    ],
    "31": [
        40682.66666666667,
        555.25
    ],
    "32": [
        42237.916666666664,
        504.7291666666667
    ],
    "33": [
        43742.645833333336,
        586.5833333333334
    ],
    "34": [
        45329.229166666664,
        683.2708333333334
    ],
    "35": [
        47012.5,
        657.1041666666667
    ],
    "36": [
        48669.604166666664,
        757.2083333333334
    ],
    "37": [
        50426.8125,
        688.75
    ],
    "38": [
        52115.5625,
        729.125
    ],
    "39": [
        53844.6875,
        711.0
    ],
    "4": [
        55555.6875,
        437.14583333333337
    ],
    "40": [
        56992.83333333333,
        574.0416666666666
    ],
    "41": [
        58566.875,
        634.6458333333334
    ],
    "42": [
        60201.520833333336,
        568.125
    ],
    "43": [
        61769.645833333336,
        650.2916666666667
    ],
    "44": [
        63419.9375,
        742.0
    ],
    "45": [
        65161.93749999999,
        786.7916666666666
    ],
    "46": [
        66948.72916666667,
        836.3958333333334
    ],
    "47": [
        68785.125,
        734.8541666666667
    ],
    "48": [
        70519.97916666667,
        768.7291666666667
    ],
    "49": [
        72288.70833333333,
        789.125
    ],
    "5": [
        74077.83333333333,
        562.2083333333333
    ],
    "50": [
        75640.04166666666,
        715.2083333333334
    ],
    "51": [
        77355.25,
        702.7708333333334
    ],
    "52": [
        79058.02083333333,
        689.4166666666666
    ],
    "53": [
        80747.4375,
        716.1041666666666
    ],
    "54": [
        82463.54166666667,
        846.9166666666666
    ],
    "55": [
        84310.45833333333,
        791.2291666666666
    ],
    "56": [
        86101.6875,
        929.7291666666667
    ],
    "57": [
        88031.41666666667,
        837.5416666666666
    ],
    "58": [
        89868.95833333334,
        858.7708333333334
    ],
    "59": [
        91727.72916666666,
        882.5625
    ],
    "6": [
        93610.29166666667,
        608.9791666666666
    ],
    "60": [
        95219.27083333334,
        690.6666666666666
    ],
    "7": [
        96909.9375,
        568.3333333333334
    ],
    "8": [
        98478.27083333334,
        572.1458333333334
    ],
    "9": [
        100050.41666666666,
        566.6458333333333
    ]
}
};

sprite_packs["fr-fr-chloe-phrases"] = {
    pack_id: "fr-fr-chloe-phrases",
    language: "fr",
    country: "fr",
    name: "Chloé",
    filename_prefix: "fr-fr-chloe-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1314.5625
    ],
    "3_periods_left": [
        3314.5625,
        1363.2291666666667
    ],
    "4_periods_left": [
        5677.791666666667,
        1469.5833333333333
    ],
    "5_periods_left": [
        8147.375,
        1416.2291666666667
    ],
    "black_wins": [
        10563.604166666666,
        1025.5416666666665
    ],
    "byoyomi": [
        12589.145833333334,
        676.6041666666666
    ],
    "challenge_received": [
        14265.75,
        779.6666666666666
    ],
    "disconnected": [
        16045.416666666668,
        774.3541666666666
    ],
    "game_paused": [
        17819.770833333332,
        416.1458333333333
    ],
    "game_resumed": [
        19235.916666666668,
        858.0833333333333
    ],
    "game_started": [
        21094.0,
        1152.7916666666667
    ],
    "last_period": [
        23246.791666666668,
        1034.6458333333333
    ],
    "overtime": [
        25281.4375,
        810.0416666666666
    ],
    "pass": [
        27091.479166666664,
        407.91666666666663
    ],
    "period": [
        28499.395833333336,
        545.7916666666666
    ],
    "player_disconnected": [
        30045.1875,
        1176.75
    ],
    "player_reconnected": [
        32221.937500000004,
        1130.9583333333333
    ],
    "reconnected": [
        34352.895833333336,
        861.8333333333334
    ],
    "remove_the_dead_stones": [
        36214.729166666664,
        1295.8958333333333
    ],
    "review_started": [
        38510.625,
        1492.1875
    ],
    "tie": [
        41002.8125,
        697.2083333333334
    ],
    "undo_granted": [
        42700.020833333336,
        1331.875
    ],
    "undo_requested": [
        45031.89583333333,
        1272.2708333333333
    ],
    "white_wins": [
        47304.166666666664,
        972.2708333333333
    ],
    "you_have_lost": [
        49276.4375,
        987.375
    ],
    "you_have_won": [
        51263.8125,
        882.9375
    ],
    "your_move": [
        53146.75,
        730.0625
    ],
    "your_opponent_has_disconnected": [
        54876.8125,
        1642.3125
    ],
    "your_opponent_has_reconnected": [
        57519.125,
        1721.9166666666667
    ]
}
};

sprite_packs["ja-jp-hitomi-numbers"] = {
    pack_id: "ja-jp-hitomi-numbers",
    language: "ja",
    country: "jp",
    name: "Hitomi",
    filename_prefix: "ja-jp-hitomi-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        685.4583333333333
    ],
    "1": [
        2685.4583333333335,
        431.4166666666667
    ],
    "10": [
        4116.875,
        519.7083333333334
    ],
    "11": [
        5636.583333333333,
        887.8541666666667
    ],
    "12": [
        7524.4375,
        672.7916666666666
    ],
    "13": [
        9197.229166666666,
        797.1458333333334
    ],
    "14": [
        10994.375,
        876.1666666666666
    ],
    "15": [
        12870.541666666666,
        674.6458333333333
    ],
    "16": [
        14545.1875,
        855.6458333333334
    ],
    "17": [
        16400.833333333336,
        778.8333333333334
    ],
    "18": [
        18179.666666666664,
        855.25
    ],
    "19": [
        20034.916666666668,
        951.1458333333334
    ],
    "2": [
        21986.0625,
        374.2916666666667
    ],
    "20": [
        23360.354166666668,
        641.875
    ],
    "21": [
        25002.229166666664,
        1037.2291666666667
    ],
    "22": [
        27039.458333333332,
        838.4791666666666
    ],
    "23": [
        28877.9375,
        944.2291666666666
    ],
    "24": [
        30822.166666666668,
        901.8333333333334
    ],
    "25": [
        32723.999999999996,
        881.0
    ],
    "26": [
        34605.0,
        1010.7291666666666
    ],
    "27": [
        36615.72916666667,
        917.7916666666666
    ],
    "28": [
        38533.520833333336,
        1074.6458333333333
    ],
    "29": [
        40608.16666666667,
        944.8125
    ],
    "3": [
        42552.979166666664,
        511.77083333333326
    ],
    "30": [
        44064.75,
        810.125
    ],
    "31": [
        45874.875,
        1254.125
    ],
    "32": [
        48129.0,
        1173.1875
    ],
    "33": [
        50302.1875,
        1255.6875
    ],
    "34": [
        52557.875,
        1245.5
    ],
    "35": [
        54803.375,
        1181.75
    ],
    "36": [
        56985.125,
        1298.6458333333333
    ],
    "37": [
        59283.770833333336,
        1201.5208333333335
    ],
    "38": [
        61485.29166666667,
        1198.4791666666667
    ],
    "39": [
        63683.770833333336,
        1221.5833333333333
    ],
    "4": [
        65905.35416666667,
        456.6458333333333
    ],
    "40": [
        67362.0,
        812.1458333333334
    ],
    "41": [
        69174.14583333333,
        1143.4375
    ],
    "42": [
        71317.58333333333,
        1026.1458333333333
    ],
    "43": [
        73343.72916666666,
        1218.6666666666665
    ],
    "44": [
        75562.39583333333,
        1057.0208333333333
    ],
    "45": [
        77619.41666666667,
        1093.4583333333335
    ],
    "46": [
        79712.875,
        1124.5833333333333
    ],
    "47": [
        81837.45833333333,
        1053.875
    ],
    "48": [
        83891.33333333333,
        1161.8958333333333
    ],
    "49": [
        86053.22916666667,
        1138.6041666666667
    ],
    "5": [
        88191.83333333333,
        316.5833333333333
    ],
    "50": [
        89508.41666666666,
        574.2083333333334
    ],
    "51": [
        91082.625,
        1054.25
    ],
    "52": [
        93136.875,
        864.3125
    ],
    "53": [
        95001.1875,
        907.2916666666667
    ],
    "54": [
        96908.47916666667,
        942.1875
    ],
    "55": [
        98850.66666666667,
        860.7291666666666
    ],
    "56": [
        100711.39583333333,
        976.375
    ],
    "57": [
        102687.77083333333,
        948.7083333333334
    ],
    "58": [
        104636.47916666666,
        1011.3541666666667
    ],
    "59": [
        106647.83333333334,
        907.3333333333334
    ],
    "6": [
        108555.16666666667,
        436.3958333333333
    ],
    "60": [
        109991.5625,
        761.625
    ],
    "7": [
        111753.1875,
        448.9375
    ],
    "8": [
        113202.125,
        488.5625
    ],
    "9": [
        114690.6875,
        426.9375
    ]
}
};

sprite_packs["ja-jp-hitomi-phrases"] = {
    pack_id: "ja-jp-hitomi-phrases",
    language: "ja",
    country: "jp",
    name: "Hitomi",
    filename_prefix: "ja-jp-hitomi-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1279.8958333333333
    ],
    "3_periods_left": [
        3279.895833333333,
        1420.2291666666667
    ],
    "4_periods_left": [
        5700.125,
        1382.2083333333333
    ],
    "5_periods_left": [
        8082.333333333333,
        1187.7916666666665
    ],
    "black_wins": [
        10270.125,
        903.4166666666666
    ],
    "byoyomi": [
        12173.541666666666,
        776.1666666666666
    ],
    "challenge_received": [
        13949.708333333334,
        1954.0625
    ],
    "disconnected": [
        16903.770833333332,
        2230.875
    ],
    "game_paused": [
        20134.645833333332,
        2555.7708333333335
    ],
    "game_resumed": [
        23690.416666666668,
        2258.4791666666665
    ],
    "game_started": [
        26948.895833333336,
        1267.2916666666667
    ],
    "last_period": [
        29216.1875,
        911.4166666666666
    ],
    "overtime": [
        31127.604166666668,
        912.375
    ],
    "pass": [
        33039.97916666667,
        379.16666666666663
    ],
    "period": [
        34419.14583333333,
        283.08333333333337
    ],
    "player_disconnected": [
        35702.22916666667,
        3113.166666666667
    ],
    "player_reconnected": [
        39815.395833333336,
        2812.4583333333335
    ],
    "reconnected": [
        43627.854166666664,
        1788.9375
    ],
    "remove_the_dead_stones": [
        46416.79166666667,
        2288.25
    ],
    "review_started": [
        49705.041666666664,
        2148.2083333333335
    ],
    "tie": [
        52853.25,
        609.5
    ],
    "undo_granted": [
        54462.75,
        2086.1666666666665
    ],
    "undo_requested": [
        57548.916666666664,
        2038.7499999999998
    ],
    "white_wins": [
        60587.666666666664,
        996.1875
    ],
    "you_have_lost": [
        62583.85416666667,
        1038.25
    ],
    "you_have_won": [
        64622.10416666667,
        1049.1041666666667
    ],
    "your_move": [
        66671.20833333334,
        1157.6458333333333
    ],
    "your_opponent_has_disconnected": [
        68828.85416666667,
        3476.4166666666665
    ],
    "your_opponent_has_reconnected": [
        73305.27083333334,
        3178.125
    ]
}
};

sprite_packs["ja-jp-kei-numbers"] = {
    pack_id: "ja-jp-kei-numbers",
    language: "ja",
    country: "jp",
    name: "Kei",
    filename_prefix: "ja-jp-kei-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        391.0416666666667
    ],
    "1": [
        2391.0416666666665,
        421.6458333333333
    ],
    "10": [
        3812.6875,
        363.4583333333333
    ],
    "11": [
        5176.145833333333,
        827.625
    ],
    "12": [
        7003.770833333333,
        649.875
    ],
    "13": [
        8653.645833333334,
        721.5
    ],
    "14": [
        10375.145833333332,
        703.2708333333333
    ],
    "15": [
        12078.416666666668,
        628.25
    ],
    "16": [
        13706.666666666668,
        812.5625
    ],
    "17": [
        15519.229166666668,
        781.1458333333334
    ],
    "18": [
        17300.375,
        843.3958333333334
    ],
    "19": [
        19143.770833333336,
        869.7916666666666
    ],
    "2": [
        21013.5625,
        287.91666666666663
    ],
    "20": [
        22301.479166666668,
        604.3333333333333
    ],
    "21": [
        23905.8125,
        916.8958333333334
    ],
    "22": [
        25822.708333333336,
        750.375
    ],
    "23": [
        27573.083333333332,
        845.2291666666667
    ],
    "24": [
        29418.3125,
        790.6666666666666
    ],
    "25": [
        31208.979166666664,
        749.9166666666666
    ],
    "26": [
        32958.895833333336,
        863.8333333333334
    ],
    "27": [
        34822.72916666667,
        842.2708333333333
    ],
    "28": [
        36665.0,
        969.125
    ],
    "29": [
        38634.125,
        972.5833333333334
    ],
    "3": [
        40606.70833333333,
        458.2291666666667
    ],
    "30": [
        42064.9375,
        883.7083333333334
    ],
    "31": [
        43948.64583333333,
        1247.6875
    ],
    "32": [
        46196.333333333336,
        1064.9791666666665
    ],
    "33": [
        48261.3125,
        1127.2083333333333
    ],
    "34": [
        50388.52083333333,
        1071.4166666666667
    ],
    "35": [
        52459.9375,
        1049.75
    ],
    "36": [
        54509.6875,
        1185.6875
    ],
    "37": [
        56695.375,
        1153.9583333333335
    ],
    "38": [
        58849.333333333336,
        1205.0625
    ],
    "39": [
        61054.39583333333,
        1225.4375
    ],
    "4": [
        63279.833333333336,
        400.8125
    ],
    "40": [
        64680.64583333333,
        824.7708333333334
    ],
    "41": [
        66505.41666666666,
        1107.0
    ],
    "42": [
        68612.41666666666,
        911.2708333333334
    ],
    "43": [
        70523.6875,
        998.6041666666666
    ],
    "44": [
        72522.29166666666,
        949.9583333333334
    ],
    "45": [
        74472.25,
        927.875
    ],
    "46": [
        76400.125,
        1033.6875
    ],
    "47": [
        78433.8125,
        995.25
    ],
    "48": [
        80429.0625,
        1089.125
    ],
    "49": [
        82518.1875,
        1143.625
    ],
    "5": [
        84661.8125,
        292.9791666666667
    ],
    "50": [
        85954.79166666667,
        584.1458333333334
    ],
    "51": [
        87538.9375,
        1028.7916666666667
    ],
    "52": [
        89567.72916666667,
        799.0416666666666
    ],
    "53": [
        91366.77083333333,
        897.0208333333334
    ],
    "54": [
        93263.79166666666,
        857.8958333333333
    ],
    "55": [
        95121.6875,
        774.7291666666667
    ],
    "56": [
        96896.41666666667,
        983.5
    ],
    "57": [
        98879.91666666667,
        887.625
    ],
    "58": [
        100767.54166666667,
        922.8333333333333
    ],
    "59": [
        102690.375,
        961.9791666666667
    ],
    "6": [
        104652.35416666667,
        446.7083333333333
    ],
    "60": [
        106099.0625,
        762.5833333333333
    ],
    "7": [
        107861.64583333333,
        423.8333333333333
    ],
    "8": [
        109285.47916666666,
        420.9583333333333
    ],
    "9": [
        110706.4375,
        355.9166666666667
    ]
}
};

sprite_packs["ja-jp-kei-phrases"] = {
    pack_id: "ja-jp-kei-phrases",
    language: "ja",
    country: "jp",
    name: "Kei",
    filename_prefix: "ja-jp-kei-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        972.2916666666666
    ],
    "3_periods_left": [
        2972.2916666666665,
        1073.0208333333333
    ],
    "4_periods_left": [
        5045.3125,
        1124.5625
    ],
    "5_periods_left": [
        7169.875,
        972.75
    ],
    "black_wins": [
        9142.625,
        811.7708333333334
    ],
    "byoyomi": [
        10954.395833333332,
        749.3958333333334
    ],
    "challenge_received": [
        12703.791666666668,
        1387.3541666666667
    ],
    "disconnected": [
        15091.145833333334,
        1943.9583333333333
    ],
    "game_paused": [
        18035.104166666668,
        1924.0416666666667
    ],
    "game_resumed": [
        20959.145833333336,
        1699.2708333333333
    ],
    "game_started": [
        23658.416666666668,
        1058.3125
    ],
    "last_period": [
        25716.729166666668,
        1005.5208333333334
    ],
    "overtime": [
        27722.25,
        846.0
    ],
    "pass": [
        29568.25,
        397.4375
    ],
    "period": [
        30965.6875,
        352.4375
    ],
    "player_disconnected": [
        32318.125000000004,
        2414.645833333333
    ],
    "player_reconnected": [
        35732.770833333336,
        2035.3750000000002
    ],
    "reconnected": [
        38768.145833333336,
        1553.5208333333333
    ],
    "remove_the_dead_stones": [
        41321.666666666664,
        1858.4791666666667
    ],
    "review_started": [
        44180.145833333336,
        1591.4583333333335
    ],
    "tie": [
        46771.60416666667,
        457.125
    ],
    "undo_granted": [
        48228.729166666664,
        1614.6875
    ],
    "undo_requested": [
        50843.41666666667,
        1612.0833333333333
    ],
    "white_wins": [
        53455.5,
        1017.1666666666667
    ],
    "you_have_lost": [
        55472.66666666667,
        917.3125
    ],
    "you_have_won": [
        57389.97916666667,
        921.1041666666666
    ],
    "your_move": [
        59311.083333333336,
        1011.5833333333333
    ],
    "your_opponent_has_disconnected": [
        61322.66666666667,
        2614.0625
    ],
    "your_opponent_has_reconnected": [
        64936.729166666664,
        2247.9583333333335
    ]
}
};

sprite_packs["ko-kr-sandra-numbers"] = {
    pack_id: "ko-kr-sandra-numbers",
    language: "ko",
    country: "kr",
    name: "Sandra",
    filename_prefix: "ko-kr-sandra-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        478.89583333333337
    ],
    "1": [
        2478.8958333333335,
        439.83333333333337
    ],
    "10": [
        3918.7291666666665,
        464.4791666666667
    ],
    "11": [
        5383.208333333333,
        645.8958333333334
    ],
    "12": [
        7029.104166666666,
        714.2708333333333
    ],
    "13": [
        8743.375,
        658.4791666666666
    ],
    "14": [
        10401.854166666666,
        590.8125
    ],
    "15": [
        11992.666666666666,
        706.0208333333334
    ],
    "16": [
        13698.6875,
        660.7708333333333
    ],
    "17": [
        15359.458333333332,
        612.5
    ],
    "18": [
        16971.958333333332,
        700.75
    ],
    "19": [
        18672.708333333332,
        535.1041666666667
    ],
    "2": [
        20207.8125,
        391.0416666666667
    ],
    "20": [
        21598.854166666664,
        622.4583333333334
    ],
    "21": [
        23221.3125,
        808.4166666666666
    ],
    "22": [
        25029.729166666664,
        771.1041666666667
    ],
    "23": [
        26800.833333333332,
        766.2083333333334
    ],
    "24": [
        28567.041666666668,
        687.0833333333334
    ],
    "25": [
        30254.125,
        761.0625
    ],
    "26": [
        32015.187500000004,
        799.6875
    ],
    "27": [
        33814.875,
        792.375
    ],
    "28": [
        35607.25,
        903.2291666666666
    ],
    "29": [
        37510.47916666667,
        741.6458333333334
    ],
    "3": [
        39252.125,
        374.7916666666667
    ],
    "30": [
        40626.916666666664,
        676.6875
    ],
    "31": [
        42303.604166666664,
        793.3125
    ],
    "32": [
        44096.916666666664,
        814.4583333333333
    ],
    "33": [
        45911.375,
        731.25
    ],
    "34": [
        47642.625,
        687.8333333333333
    ],
    "35": [
        49330.458333333336,
        808.0
    ],
    "36": [
        51138.458333333336,
        815.5
    ],
    "37": [
        52953.958333333336,
        780.25
    ],
    "38": [
        54734.208333333336,
        897.25
    ],
    "39": [
        56631.458333333336,
        769.125
    ],
    "4": [
        58400.58333333333,
        311.9166666666667
    ],
    "40": [
        59712.5,
        621.3958333333334
    ],
    "41": [
        61333.895833333336,
        823.9166666666666
    ],
    "42": [
        63157.8125,
        803.3541666666667
    ],
    "43": [
        64961.16666666667,
        729.625
    ],
    "44": [
        66690.79166666667,
        734.7708333333334
    ],
    "45": [
        68425.5625,
        796.1666666666667
    ],
    "46": [
        70221.72916666666,
        830.6875
    ],
    "47": [
        72052.41666666667,
        751.25
    ],
    "48": [
        73803.66666666667,
        869.375
    ],
    "49": [
        75673.04166666666,
        728.6458333333334
    ],
    "5": [
        77401.6875,
        450.0833333333333
    ],
    "50": [
        78851.77083333333,
        589.2916666666666
    ],
    "51": [
        80441.0625,
        810.2708333333334
    ],
    "52": [
        82251.33333333333,
        859.6666666666667
    ],
    "53": [
        84111.0,
        687.5833333333334
    ],
    "54": [
        85798.58333333334,
        670.4583333333334
    ],
    "55": [
        87469.04166666667,
        771.4375
    ],
    "56": [
        89240.47916666666,
        839.1666666666666
    ],
    "57": [
        91079.64583333333,
        724.1666666666666
    ],
    "58": [
        92803.8125,
        881.875
    ],
    "59": [
        94685.6875,
        672.0416666666666
    ],
    "6": [
        96357.72916666667,
        477.1666666666667
    ],
    "60": [
        97834.89583333333,
        611.2708333333334
    ],
    "7": [
        99446.16666666667,
        484.70833333333337
    ],
    "8": [
        100930.875,
        601.6458333333334
    ],
    "9": [
        102532.52083333334,
        414.5208333333333
    ]
}
};

sprite_packs["ko-kr-sandra-phrases"] = {
    pack_id: "ko-kr-sandra-phrases",
    language: "ko",
    country: "kr",
    name: "Sandra",
    filename_prefix: "ko-kr-sandra-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1354.0208333333333
    ],
    "3_periods_left": [
        3354.020833333333,
        1465.3541666666667
    ],
    "4_periods_left": [
        5819.375,
        1391.6041666666667
    ],
    "5_periods_left": [
        8210.979166666666,
        1491.875
    ],
    "black_wins": [
        10702.854166666666,
        906.8125
    ],
    "byoyomi": [
        12609.666666666668,
        792.0208333333333
    ],
    "challenge_received": [
        14401.6875,
        1178.6875
    ],
    "disconnected": [
        16580.375,
        1254.2708333333333
    ],
    "game_paused": [
        18834.645833333332,
        1030.5625
    ],
    "game_resumed": [
        20865.208333333332,
        972.2291666666667
    ],
    "game_started": [
        22837.4375,
        861.8125
    ],
    "last_period": [
        24699.25,
        985.5208333333333
    ],
    "overtime": [
        26684.770833333336,
        992.2916666666666
    ],
    "pass": [
        28677.0625,
        649.4166666666666
    ],
    "period": [
        30326.479166666664,
        435.33333333333337
    ],
    "player_disconnected": [
        31761.8125,
        2199.125
    ],
    "player_reconnected": [
        34960.9375,
        2461.0208333333335
    ],
    "reconnected": [
        38421.958333333336,
        1353.3333333333333
    ],
    "remove_the_dead_stones": [
        40775.291666666664,
        1140.2708333333333
    ],
    "review_started": [
        42915.5625,
        878.8541666666667
    ],
    "start_counting": [
        44794.416666666664,
        1562.3125
    ],
    "tie": [
        47356.729166666664,
        674.1041666666666
    ],
    "undo_granted": [
        49030.833333333336,
        1011.3541666666667
    ],
    "undo_requested": [
        51042.1875,
        1208.3541666666665
    ],
    "white_wins": [
        53250.541666666664,
        926.9166666666667
    ],
    "you_have_lost": [
        55177.458333333336,
        1026.375
    ],
    "you_have_won": [
        57203.833333333336,
        1156.9166666666665
    ],
    "your_move": [
        59360.75,
        839.4166666666667
    ],
    "your_opponent_has_disconnected": [
        61200.16666666667,
        2060.4791666666665
    ],
    "your_opponent_has_reconnected": [
        64260.64583333333,
        2411.020833333333
    ]
}
};

sprite_packs["ru-ru-alevtina-numbers"] = {
    pack_id: "ru-ru-alevtina-numbers",
    language: "ru",
    country: "ru",
    name: "Alevtina  ",
    filename_prefix: "ru-ru-alevtina-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        343.7916666666667
    ],
    "1": [
        2343.7916666666665,
        417.3541666666667
    ],
    "10": [
        3761.1458333333335,
        553.0833333333334
    ],
    "11": [
        5314.229166666666,
        734.125
    ],
    "12": [
        7048.354166666667,
        736.6041666666666
    ],
    "13": [
        8784.958333333334,
        675.6875
    ],
    "14": [
        10460.645833333332,
        790.75
    ],
    "15": [
        12251.395833333334,
        669.6875
    ],
    "16": [
        13921.083333333334,
        744.2916666666666
    ],
    "17": [
        15665.375,
        746.9583333333333
    ],
    "18": [
        17412.333333333332,
        811.125
    ],
    "19": [
        19223.458333333332,
        862.9375
    ],
    "2": [
        21086.395833333336,
        348.72916666666663
    ],
    "20": [
        22435.125,
        640.7083333333334
    ],
    "21": [
        24075.833333333332,
        768.0208333333334
    ],
    "22": [
        25843.854166666668,
        696.8958333333334
    ],
    "23": [
        27540.75,
        654.9583333333333
    ],
    "24": [
        29195.708333333332,
        883.2916666666667
    ],
    "25": [
        31079.0,
        786.1875
    ],
    "26": [
        32865.1875,
        853.9166666666666
    ],
    "27": [
        34719.104166666664,
        745.75
    ],
    "28": [
        36464.85416666667,
        853.8125
    ],
    "29": [
        38318.666666666664,
        883.7083333333334
    ],
    "3": [
        40202.375,
        261.9166666666667
    ],
    "30": [
        41464.29166666667,
        529.9791666666666
    ],
    "31": [
        42994.27083333333,
        712.4375
    ],
    "32": [
        44706.70833333333,
        645.7708333333333
    ],
    "33": [
        46352.47916666667,
        639.1875
    ],
    "34": [
        47991.666666666664,
        823.8125
    ],
    "35": [
        49815.47916666667,
        714.125
    ],
    "36": [
        51529.604166666664,
        774.8958333333334
    ],
    "37": [
        53304.5,
        654.3541666666666
    ],
    "38": [
        54958.85416666667,
        785.8541666666666
    ],
    "39": [
        56744.708333333336,
        769.3958333333333
    ],
    "4": [
        58514.10416666667,
        512.8125
    ],
    "40": [
        60026.916666666664,
        499.3125
    ],
    "41": [
        61526.229166666664,
        708.0
    ],
    "42": [
        63234.229166666664,
        618.0833333333334
    ],
    "43": [
        64852.3125,
        613.6458333333334
    ],
    "44": [
        66465.95833333333,
        790.7291666666667
    ],
    "45": [
        68256.6875,
        706.5416666666666
    ],
    "46": [
        69963.22916666667,
        793.5416666666667
    ],
    "47": [
        71756.77083333333,
        653.6666666666666
    ],
    "48": [
        73410.4375,
        816.2916666666666
    ],
    "49": [
        75226.72916666667,
        788.7291666666667
    ],
    "5": [
        77015.45833333333,
        343.0416666666667
    ],
    "50": [
        78358.5,
        617.6458333333334
    ],
    "51": [
        79976.14583333333,
        803.1875
    ],
    "52": [
        81779.33333333333,
        760.1666666666666
    ],
    "53": [
        83539.5,
        760.2083333333334
    ],
    "54": [
        85299.70833333333,
        975.75
    ],
    "55": [
        87275.45833333333,
        884.9375
    ],
    "56": [
        89160.39583333334,
        994.5833333333334
    ],
    "57": [
        91154.97916666666,
        817.9166666666666
    ],
    "58": [
        92972.89583333334,
        940.1666666666667
    ],
    "59": [
        94913.0625,
        940.1875
    ],
    "6": [
        96853.25,
        483.125
    ],
    "60": [
        98336.375,
        646.3541666666666
    ],
    "7": [
        99982.72916666667,
        375.35416666666663
    ],
    "8": [
        101358.08333333334,
        479.20833333333337
    ],
    "9": [
        102837.29166666667,
        512.3125
    ]
}
};

sprite_packs["ru-ru-alevtina-phrases"] = {
    pack_id: "ru-ru-alevtina-phrases",
    language: "ru",
    country: "ru",
    name: "Alevtina",
    filename_prefix: "ru-ru-alevtina-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1580.3125
    ],
    "3_periods_left": [
        3580.3125,
        1456.2708333333335
    ],
    "4_periods_left": [
        6036.583333333334,
        1740.375
    ],
    "5_periods_left": [
        8776.958333333332,
        1629.5
    ],
    "black_wins": [
        11406.458333333334,
        1161.3541666666667
    ],
    "byoyomi": [
        13567.8125,
        682.6458333333333
    ],
    "challenge_received": [
        15250.458333333332,
        1004.3541666666666
    ],
    "disconnected": [
        17254.8125,
        644.4166666666666
    ],
    "game_paused": [
        18899.229166666668,
        1178.5625
    ],
    "game_resumed": [
        21077.791666666668,
        1110.7916666666665
    ],
    "game_started": [
        23188.583333333332,
        1042.5208333333333
    ],
    "last_period": [
        25231.104166666668,
        1103.3333333333333
    ],
    "overtime": [
        27334.4375,
        1248.1875
    ],
    "pass": [
        29582.625,
        372.875
    ],
    "period": [
        30955.5,
        570.0625
    ],
    "player_disconnected": [
        32525.5625,
        1814.6666666666667
    ],
    "player_reconnected": [
        35340.229166666664,
        1432.3541666666665
    ],
    "reconnected": [
        37772.58333333333,
        803.6041666666666
    ],
    "remove_the_dead_stones": [
        39576.1875,
        1491.0
    ],
    "review_started": [
        42067.1875,
        1020.7916666666667
    ],
    "tie": [
        44087.979166666664,
        567.4166666666666
    ],
    "undo_granted": [
        45655.39583333333,
        1146.3958333333333
    ],
    "undo_requested": [
        47801.791666666664,
        1446.0625
    ],
    "white_wins": [
        50247.85416666667,
        1121.8958333333333
    ],
    "you_have_lost": [
        52369.75,
        823.4583333333333
    ],
    "you_have_won": [
        54193.20833333333,
        933.9791666666667
    ],
    "your_move": [
        56127.1875,
        628.375
    ],
    "your_opponent_has_disconnected": [
        57755.5625,
        1901.9791666666667
    ],
    "your_opponent_has_reconnected": [
        60657.541666666664,
        1541.1458333333335
    ]
}
};

sprite_packs["zh-cn-inni-numbers"] = {
    pack_id: "zh-cn-inni-numbers",
    language: "zh",
    country: "cn",
    name: "Inni  ",
    filename_prefix: "zh-cn-inni-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "0": [
        1000.0,
        399.7083333333333
    ],
    "1": [
        2399.7083333333335,
        301.54166666666663
    ],
    "10": [
        3701.25,
        389.0208333333333
    ],
    "11": [
        5090.270833333333,
        572.1041666666666
    ],
    "12": [
        6662.375,
        637.1458333333333
    ],
    "13": [
        8299.520833333334,
        579.7291666666666
    ],
    "14": [
        9879.25,
        567.375
    ],
    "15": [
        11446.625,
        496.25
    ],
    "16": [
        12942.875,
        577.2083333333334
    ],
    "17": [
        14520.083333333334,
        566.6666666666666
    ],
    "18": [
        16086.749999999998,
        601.4583333333334
    ],
    "19": [
        17688.208333333332,
        611.5208333333333
    ],
    "2": [
        19299.729166666664,
        304.52083333333337
    ],
    "20": [
        20604.25,
        598.6458333333334
    ],
    "21": [
        22202.895833333332,
        564.8541666666666
    ],
    "22": [
        23767.75,
        596.8333333333334
    ],
    "23": [
        25364.583333333332,
        617.5
    ],
    "24": [
        26982.083333333332,
        618.25
    ],
    "25": [
        28600.333333333332,
        510.31249999999994
    ],
    "26": [
        30110.645833333332,
        611.3125
    ],
    "27": [
        31721.958333333332,
        589.8125
    ],
    "28": [
        33311.770833333336,
        587.7708333333334
    ],
    "29": [
        34899.541666666664,
        576.6666666666666
    ],
    "3": [
        36476.208333333336,
        389.77083333333337
    ],
    "30": [
        37865.97916666667,
        614.7916666666666
    ],
    "31": [
        39480.77083333333,
        745.2083333333334
    ],
    "32": [
        41225.97916666667,
        697.3333333333334
    ],
    "33": [
        42923.3125,
        657.9583333333333
    ],
    "34": [
        44581.270833333336,
        730.2708333333333
    ],
    "35": [
        46311.541666666664,
        685.9375
    ],
    "36": [
        47997.479166666664,
        736.4375
    ],
    "37": [
        49733.916666666664,
        697.1041666666666
    ],
    "38": [
        51431.020833333336,
        732.3333333333333
    ],
    "39": [
        53163.354166666664,
        676.3125
    ],
    "4": [
        54839.666666666664,
        392.27083333333337
    ],
    "40": [
        56231.9375,
        600.7083333333333
    ],
    "41": [
        57832.64583333333,
        656.375
    ],
    "42": [
        59489.020833333336,
        686.0625
    ],
    "43": [
        61175.083333333336,
        692.6875
    ],
    "44": [
        62867.77083333333,
        740.0208333333334
    ],
    "45": [
        64607.79166666667,
        612.1875
    ],
    "46": [
        66219.97916666666,
        618.7916666666666
    ],
    "47": [
        67838.77083333333,
        668.5833333333333
    ],
    "48": [
        69507.35416666667,
        636.0833333333334
    ],
    "49": [
        71143.4375,
        612.2291666666667
    ],
    "5": [
        72755.66666666667,
        270.125
    ],
    "50": [
        74025.79166666666,
        585.875
    ],
    "51": [
        75611.66666666667,
        584.0625
    ],
    "52": [
        77195.72916666667,
        605.9166666666666
    ],
    "53": [
        78801.64583333334,
        620.5416666666666
    ],
    "54": [
        80422.1875,
        626.1041666666666
    ],
    "55": [
        82048.29166666667,
        553.9375
    ],
    "56": [
        83602.22916666666,
        624.5208333333333
    ],
    "57": [
        85226.75,
        631.0416666666666
    ],
    "58": [
        86857.79166666667,
        648.5
    ],
    "59": [
        88506.29166666667,
        607.625
    ],
    "6": [
        90113.91666666667,
        354.0625
    ],
    "60": [
        91467.97916666667,
        582.6666666666666
    ],
    "7": [
        93050.64583333333,
        368.5625
    ],
    "8": [
        94419.20833333333,
        321.7083333333333
    ],
    "9": [
        95740.91666666666,
        343.0625
    ]
}
};

sprite_packs["zh-cn-inni-phrases"] = {
    pack_id: "zh-cn-inni-phrases",
    language: "zh",
    country: "cn",
    name: "Inni",
    filename_prefix: "zh-cn-inni-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1128.375
    ],
    "3_periods_left": [
        3128.375,
        1148.4375
    ],
    "4_periods_left": [
        5276.8125,
        1127.3541666666667
    ],
    "5_periods_left": [
        7404.166666666667,
        990.25
    ],
    "black_wins": [
        9394.416666666666,
        616.4791666666667
    ],
    "byoyomi": [
        11010.895833333332,
        523.3125
    ],
    "challenge_received": [
        12534.208333333334,
        1113.2291666666667
    ],
    "disconnected": [
        14647.4375,
        786.4583333333334
    ],
    "game_paused": [
        16433.895833333336,
        1021.1250000000001
    ],
    "game_resumed": [
        18455.020833333332,
        959.0208333333334
    ],
    "game_started": [
        20414.041666666664,
        894.1041666666667
    ],
    "last_period": [
        22308.145833333336,
        920.7291666666667
    ],
    "overtime": [
        24228.875,
        720.625
    ],
    "pass": [
        25949.5,
        687.8541666666666
    ],
    "period": [
        27637.354166666668,
        354.4791666666667
    ],
    "player_disconnected": [
        28991.833333333332,
        2189.9166666666665
    ],
    "player_reconnected": [
        32181.75,
        2390.25
    ],
    "reconnected": [
        35572.0,
        964.0625
    ],
    "remove_the_dead_stones": [
        37536.0625,
        1346.4375
    ],
    "review_started": [
        39882.5,
        1189.375
    ],
    "start_counting": [
        42071.875,
        1144.8541666666667
    ],
    "tie": [
        44216.729166666664,
        711.75
    ],
    "undo_granted": [
        45928.47916666667,
        1092.1666666666667
    ],
    "undo_requested": [
        48020.645833333336,
        1289.5208333333333
    ],
    "white_wins": [
        50310.166666666664,
        576.2083333333334
    ],
    "you_have_lost": [
        51886.375,
        874.8958333333334
    ],
    "you_have_won": [
        53761.270833333336,
        790.2916666666666
    ],
    "your_move": [
        55551.5625,
        1135.75
    ],
    "your_opponent_has_disconnected": [
        57687.3125,
        2272.0
    ],
    "your_opponent_has_reconnected": [
        60959.3125,
        2407.125
    ]
}
};

sprite_packs["zh-hk-bi-numbers"] = {
    pack_id: "zh-hk-bi-numbers",
    language: "zh",
    country: "hk",
    name: "Bi",
    filename_prefix: "zh-hk-bi-numbers.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "1": [
        1000.0,
        266.7083333333333
    ],
    "10": [
        2266.7083333333335,
        476.6041666666667
    ],
    "11": [
        3743.3125,
        615.2291666666667
    ],
    "12": [
        5358.541666666667,
        717.4583333333333
    ],
    "13": [
        7076.0,
        795.0625
    ],
    "14": [
        8871.0625,
        726.3541666666666
    ],
    "15": [
        10597.416666666666,
        869.3958333333334
    ],
    "16": [
        12466.8125,
        665.5
    ],
    "17": [
        14132.3125,
        640.1875
    ],
    "18": [
        15772.5,
        620.6458333333334
    ],
    "19": [
        17393.145833333332,
        804.125
    ],
    "2": [
        19197.270833333336,
        460.25
    ],
    "20": [
        20657.520833333332,
        567.1041666666666
    ],
    "21": [
        22224.625,
        629.5625
    ],
    "22": [
        23854.1875,
        778.6041666666666
    ],
    "23": [
        25632.791666666664,
        980.3958333333334
    ],
    "24": [
        27613.1875,
        869.3958333333334
    ],
    "25": [
        29482.583333333336,
        931.5833333333334
    ],
    "26": [
        31414.166666666668,
        790.4166666666666
    ],
    "27": [
        33204.58333333333,
        711.1041666666666
    ],
    "28": [
        34915.6875,
        827.4583333333333
    ],
    "29": [
        36743.145833333336,
        877.3541666666666
    ],
    "3": [
        38620.5,
        708.8333333333334
    ],
    "30": [
        40329.33333333333,
        820.125
    ],
    "31": [
        42149.458333333336,
        959.7916666666667
    ],
    "32": [
        44109.25,
        965.0
    ],
    "33": [
        46074.25,
        1166.4166666666667
    ],
    "34": [
        48240.66666666667,
        1069.8541666666667
    ],
    "35": [
        50310.520833333336,
        1074.1666666666667
    ],
    "36": [
        52384.6875,
        826.6875
    ],
    "37": [
        54211.375,
        969.3333333333334
    ],
    "38": [
        56180.708333333336,
        950.3958333333334
    ],
    "39": [
        58131.104166666664,
        1067.2708333333335
    ],
    "4": [
        60198.375,
        725.0625
    ],
    "40": [
        61923.4375,
        807.2291666666666
    ],
    "41": [
        63730.666666666664,
        946.1458333333334
    ],
    "42": [
        65676.8125,
        1094.9166666666667
    ],
    "43": [
        67771.72916666666,
        1053.3541666666665
    ],
    "44": [
        69825.08333333334,
        1167.5
    ],
    "45": [
        71992.58333333333,
        1026.0
    ],
    "46": [
        74018.58333333334,
        801.75
    ],
    "47": [
        75820.33333333334,
        972.4166666666667
    ],
    "48": [
        77792.75,
        1084.3541666666667
    ],
    "49": [
        79877.10416666667,
        1079.0625
    ],
    "5": [
        81956.16666666666,
        618.2083333333334
    ],
    "50": [
        83574.375,
        657.6666666666666
    ],
    "51": [
        85232.04166666666,
        727.9166666666666
    ],
    "52": [
        86959.95833333333,
        805.7291666666667
    ],
    "53": [
        88765.6875,
        1001.7708333333333
    ],
    "54": [
        90767.45833333334,
        965.4791666666666
    ],
    "55": [
        92732.9375,
        946.7708333333334
    ],
    "56": [
        94679.70833333334,
        690.2916666666666
    ],
    "57": [
        96370.0,
        745.2291666666667
    ],
    "58": [
        98115.22916666667,
        786.2291666666666
    ],
    "59": [
        99901.45833333334,
        893.375
    ],
    "6": [
        101794.83333333333,
        297.3125
    ],
    "60": [
        103092.14583333333,
        607.7916666666666
    ],
    "7": [
        104699.9375,
        475.20833333333337
    ],
    "8": [
        106175.14583333333,
        319.2708333333333
    ],
    "9": [
        107494.41666666667,
        476.75
    ]
}
};

sprite_packs["zh-hk-bi-phrases"] = {
    pack_id: "zh-hk-bi-phrases",
    language: "zh",
    country: "hk",
    name: "Bi",
    filename_prefix: "zh-hk-bi-phrases.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "2_periods_left": [
        1000.0,
        1712.6875
    ],
    "3_periods_left": [
        3712.6875,
        1761.3958333333333
    ],
    "4_periods_left": [
        6474.083333333334,
        1620.6875
    ],
    "5_periods_left": [
        9094.770833333334,
        1490.5208333333333
    ],
    "black_wins": [
        11585.291666666666,
        935.375
    ],
    "byoyomi": [
        13520.666666666668,
        764.0625
    ],
    "challenge_received": [
        15284.729166666666,
        1588.75
    ],
    "disconnected": [
        17873.479166666664,
        929.6041666666666
    ],
    "game_paused": [
        19803.083333333332,
        1244.25
    ],
    "game_resumed": [
        22047.333333333336,
        1041.1666666666665
    ],
    "game_started": [
        24088.5,
        1257.4583333333333
    ],
    "last_period": [
        26345.958333333332,
        1269.3958333333333
    ],
    "overtime": [
        28615.354166666668,
        878.1875
    ],
    "pass": [
        30493.541666666664,
        997.7291666666666
    ],
    "period": [
        32491.270833333332,
        583.0833333333333
    ],
    "player_disconnected": [
        34074.354166666664,
        1995.6041666666665
    ],
    "player_reconnected": [
        37069.958333333336,
        2095.666666666667
    ],
    "reconnected": [
        40165.625,
        792.25
    ],
    "remove_the_dead_stones": [
        41957.875,
        1312.375
    ],
    "review_started": [
        44270.25,
        1439.1875
    ],
    "start_counting": [
        46709.4375,
        1460.75
    ],
    "tie": [
        49170.1875,
        677.7291666666667
    ],
    "undo_granted": [
        50847.91666666667,
        1281.0208333333333
    ],
    "undo_requested": [
        53128.9375,
        1357.7916666666667
    ],
    "white_wins": [
        55486.72916666667,
        808.1666666666667
    ],
    "you_have_lost": [
        57294.895833333336,
        1000.1249999999999
    ],
    "you_have_won": [
        59295.020833333336,
        891.75
    ],
    "your_move": [
        61186.770833333336,
        1148.375
    ],
    "your_opponent_has_disconnected": [
        63335.145833333336,
        2341.625
    ],
    "your_opponent_has_reconnected": [
        66676.77083333334,
        2491.416666666667
    ]
}
};

sprite_packs["zz-un-effects-2012"] = {
    pack_id: "zz-un-effects-2012",
    language: "zz",
    country: "un",
    name: "Effects (2012)",
    filename_prefix: "zz-un-effects-2012.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "beep": [
        1000.0,
        127.70833333333334
    ],
    "beepbeep": [
        2127.7083333333335,
        374.97916666666663
    ],
    "capture-1-pile": [
        3502.6875,
        507.1041666666667
    ],
    "capture-1": [
        5009.791666666667,
        1074.6458333333333
    ],
    "capture-2-pile": [
        7084.4375,
        619.1458333333333
    ],
    "capture-2": [
        8703.583333333332,
        1485.9166666666667
    ],
    "capture-3-pile": [
        11189.5,
        852.3125
    ],
    "capture-3": [
        13041.8125,
        1034.9375
    ],
    "capture-4-pile": [
        15076.75,
        679.7708333333334
    ],
    "capture-4": [
        16756.520833333332,
        895.8541666666666
    ],
    "capture-5": [
        18652.375,
        1148.8125
    ],
    "capture-handful": [
        20801.1875,
        1145.3333333333333
    ],
    "challenge_received": [
        22946.520833333336,
        1400.0
    ],
    "disconnected": [
        25346.520833333332,
        900.8541666666666
    ],
    "error": [
        27247.375,
        600.0
    ],
    "out": [
        28847.375,
        374.97916666666663
    ],
    "pass": [
        30222.354166666664,
        127.70833333333334
    ],
    "period": [
        31350.0625,
        210.0
    ],
    "put-lid-on": [
        32560.0625,
        477.875
    ],
    "reconnected": [
        34037.9375,
        751.4583333333334
    ],
    "setup-bowl": [
        35789.39583333333,
        1322.7708333333333
    ],
    "tick-2left": [
        38112.166666666664,
        2000.0
    ],
    "tick": [
        41112.166666666664,
        90.0625
    ],
    "tock-1left": [
        42202.22916666667,
        2827.2708333333335
    ],
    "tock-3left": [
        46029.5,
        1000.0
    ],
    "tock": [
        48029.5,
        92.1875
    ],
    "tutorial-bling": [
        49121.6875,
        626.9375
    ],
    "tutorial-fail": [
        50748.625,
        552.4791666666666
    ],
    "tutorial-pass": [
        52301.10416666667,
        626.9375
    ],
    "tutorial-ping": [
        53928.041666666664,
        2548.395833333333
    ],
    "undo_requested": [
        57476.4375,
        1000.0208333333334
    ],
    "0": [
        42202.22916666667,
        2827.2708333333335
    ],
    "1": [
        42202.22916666667,
        2827.2708333333335
    ],
    "2": [
        38112.166666666664,
        2000.0
    ],
    "3": [
        46029.5,
        1000.0
    ],
    "4": [
        41112.166666666664,
        90.0625
    ],
    "5": [
        48029.5,
        92.1875
    ],
    "6": [
        41112.166666666664,
        90.0625
    ],
    "7": [
        48029.5,
        92.1875
    ],
    "8": [
        41112.166666666664,
        90.0625
    ],
    "9": [
        48029.5,
        92.1875
    ],
    "10": [
        41112.166666666664,
        90.0625
    ],
    "11": [
        48029.5,
        92.1875
    ],
    "12": [
        41112.166666666664,
        90.0625
    ],
    "13": [
        48029.5,
        92.1875
    ],
    "14": [
        41112.166666666664,
        90.0625
    ],
    "15": [
        48029.5,
        92.1875
    ],
    "16": [
        41112.166666666664,
        90.0625
    ],
    "17": [
        48029.5,
        92.1875
    ],
    "18": [
        41112.166666666664,
        90.0625
    ],
    "19": [
        48029.5,
        92.1875
    ],
    "20": [
        41112.166666666664,
        90.0625
    ],
    "21": [
        48029.5,
        92.1875
    ],
    "22": [
        41112.166666666664,
        90.0625
    ],
    "23": [
        48029.5,
        92.1875
    ],
    "24": [
        41112.166666666664,
        90.0625
    ],
    "25": [
        48029.5,
        92.1875
    ],
    "26": [
        41112.166666666664,
        90.0625
    ],
    "27": [
        48029.5,
        92.1875
    ],
    "28": [
        41112.166666666664,
        90.0625
    ],
    "29": [
        48029.5,
        92.1875
    ],
    "30": [
        41112.166666666664,
        90.0625
    ],
    "31": [
        48029.5,
        92.1875
    ],
    "32": [
        41112.166666666664,
        90.0625
    ],
    "33": [
        48029.5,
        92.1875
    ],
    "34": [
        41112.166666666664,
        90.0625
    ],
    "35": [
        48029.5,
        92.1875
    ],
    "36": [
        41112.166666666664,
        90.0625
    ],
    "37": [
        48029.5,
        92.1875
    ],
    "38": [
        41112.166666666664,
        90.0625
    ],
    "39": [
        48029.5,
        92.1875
    ],
    "40": [
        41112.166666666664,
        90.0625
    ],
    "41": [
        48029.5,
        92.1875
    ],
    "42": [
        41112.166666666664,
        90.0625
    ],
    "43": [
        48029.5,
        92.1875
    ],
    "44": [
        41112.166666666664,
        90.0625
    ],
    "45": [
        48029.5,
        92.1875
    ],
    "46": [
        41112.166666666664,
        90.0625
    ],
    "47": [
        48029.5,
        92.1875
    ],
    "48": [
        41112.166666666664,
        90.0625
    ],
    "49": [
        48029.5,
        92.1875
    ],
    "50": [
        41112.166666666664,
        90.0625
    ],
    "51": [
        48029.5,
        92.1875
    ],
    "52": [
        41112.166666666664,
        90.0625
    ],
    "53": [
        48029.5,
        92.1875
    ],
    "54": [
        41112.166666666664,
        90.0625
    ],
    "55": [
        48029.5,
        92.1875
    ],
    "56": [
        41112.166666666664,
        90.0625
    ],
    "57": [
        48029.5,
        92.1875
    ],
    "58": [
        41112.166666666664,
        90.0625
    ],
    "59": [
        48029.5,
        92.1875
    ],
    "60": [
        41112.166666666664,
        90.0625
    ],
    "2_periods_left": [
        31350.0625,
        210.0
    ],
    "3_periods_left": [
        31350.0625,
        210.0
    ],
    "4_periods_left": [
        31350.0625,
        210.0
    ],
    "5_periods_left": [
        31350.0625,
        210.0
    ],
    "last_period": [
        31350.0625,
        210.0
    ],
    "byoyomi": [
        31350.0625,
        210.0
    ],
    "overtime": [
        31350.0625,
        210.0
    ],
    "your_opponent_has_disconnected": [
        25346.520833333332,
        900.8541666666666
    ],
    "your_opponent_has_reconnected": [
        34037.9375,
        751.4583333333334
    ],
    "player_disconnected": [
        25346.520833333332,
        900.8541666666666
    ],
    "player_reconnected": [
        34037.9375,
        751.4583333333334
    ],
    "undo_granted": [
        34037.9375,
        751.4583333333334
    ],
    "game_paused": [
        0,
        50
    ],
    "game_resumed": [
        0,
        50
    ],
    "remove_the_dead_stones": [
        0,
        50
    ],
    "review_started": [
        0,
        50
    ],
    "tournament_starting": [
        0,
        50
    ]
}
};

sprite_packs["zz-un-effects"] = {
    pack_id: "zz-un-effects",
    language: "zz",
    country: "un",
    name: "Effects",
    filename_prefix: "zz-un-effects.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "capture-1-pile": [
        1000.0,
        507.1041666666667
    ],
    "capture-1": [
        2507.1041666666665,
        1074.6458333333333
    ],
    "capture-2-pile": [
        4581.75,
        619.1458333333333
    ],
    "capture-2": [
        6200.895833333333,
        1485.9166666666667
    ],
    "capture-3-pile": [
        8686.8125,
        852.3125
    ],
    "capture-3": [
        10539.125,
        1034.9375
    ],
    "capture-4-pile": [
        12574.0625,
        679.7708333333334
    ],
    "capture-4": [
        14253.833333333332,
        895.8541666666666
    ],
    "capture-5": [
        16149.687499999998,
        1148.8125
    ],
    "capture-handful": [
        18298.5,
        1145.3333333333333
    ],
    "challenge_received": [
        20443.833333333336,
        1400.0
    ],
    "click": [
        22843.833333333332,
        61.66666666666667
    ],
    "disconnected": [
        23905.5,
        900.8541666666666
    ],
    "error": [
        25806.354166666664,
        600.0
    ],
    "pass": [
        27406.354166666668,
        1000.0208333333334
    ],
    "period": [
        29406.375,
        210.0
    ],
    "put-lid-on": [
        30616.375,
        2301.8125
    ],
    "reconnected": [
        33918.1875,
        751.4583333333334
    ],
    "setup-bowl": [
        35669.645833333336,
        1322.7708333333333
    ],
    "stones-put-lid-on": [
        37992.416666666664,
        2301.8125
    ],
    "tick-2left": [
        41294.229166666664,
        2000.0
    ],
    "tick": [
        44294.229166666664,
        90.0625
    ],
    "tock-1left": [
        45384.29166666667,
        2827.2708333333335
    ],
    "tock-3left": [
        49211.5625,
        1000.0
    ],
    "tock": [
        51211.5625,
        92.1875
    ],
    "tutorial-bling": [
        52303.75,
        626.9375
    ],
    "tutorial-fail": [
        53930.6875,
        552.4791666666666
    ],
    "tutorial-pass": [
        55483.16666666667,
        626.9375
    ],
    "tutorial-ping": [
        57110.104166666664,
        2548.395833333333
    ],
    "undo_requested": [
        60658.5,
        1000.0208333333334
    ],
    "0": [
        45384.29166666667,
        2827.2708333333335
    ],
    "1": [
        45384.29166666667,
        2827.2708333333335
    ],
    "2": [
        41294.229166666664,
        2000.0
    ],
    "3": [
        49211.5625,
        1000.0
    ],
    "4": [
        44294.229166666664,
        90.0625
    ],
    "5": [
        51211.5625,
        92.1875
    ],
    "6": [
        44294.229166666664,
        90.0625
    ],
    "7": [
        51211.5625,
        92.1875
    ],
    "8": [
        44294.229166666664,
        90.0625
    ],
    "9": [
        51211.5625,
        92.1875
    ],
    "10": [
        44294.229166666664,
        90.0625
    ],
    "11": [
        51211.5625,
        92.1875
    ],
    "12": [
        44294.229166666664,
        90.0625
    ],
    "13": [
        51211.5625,
        92.1875
    ],
    "14": [
        44294.229166666664,
        90.0625
    ],
    "15": [
        51211.5625,
        92.1875
    ],
    "16": [
        44294.229166666664,
        90.0625
    ],
    "17": [
        51211.5625,
        92.1875
    ],
    "18": [
        44294.229166666664,
        90.0625
    ],
    "19": [
        51211.5625,
        92.1875
    ],
    "20": [
        44294.229166666664,
        90.0625
    ],
    "21": [
        51211.5625,
        92.1875
    ],
    "22": [
        44294.229166666664,
        90.0625
    ],
    "23": [
        51211.5625,
        92.1875
    ],
    "24": [
        44294.229166666664,
        90.0625
    ],
    "25": [
        51211.5625,
        92.1875
    ],
    "26": [
        44294.229166666664,
        90.0625
    ],
    "27": [
        51211.5625,
        92.1875
    ],
    "28": [
        44294.229166666664,
        90.0625
    ],
    "29": [
        51211.5625,
        92.1875
    ],
    "30": [
        44294.229166666664,
        90.0625
    ],
    "31": [
        51211.5625,
        92.1875
    ],
    "32": [
        44294.229166666664,
        90.0625
    ],
    "33": [
        51211.5625,
        92.1875
    ],
    "34": [
        44294.229166666664,
        90.0625
    ],
    "35": [
        51211.5625,
        92.1875
    ],
    "36": [
        44294.229166666664,
        90.0625
    ],
    "37": [
        51211.5625,
        92.1875
    ],
    "38": [
        44294.229166666664,
        90.0625
    ],
    "39": [
        51211.5625,
        92.1875
    ],
    "40": [
        44294.229166666664,
        90.0625
    ],
    "41": [
        51211.5625,
        92.1875
    ],
    "42": [
        44294.229166666664,
        90.0625
    ],
    "43": [
        51211.5625,
        92.1875
    ],
    "44": [
        44294.229166666664,
        90.0625
    ],
    "45": [
        51211.5625,
        92.1875
    ],
    "46": [
        44294.229166666664,
        90.0625
    ],
    "47": [
        51211.5625,
        92.1875
    ],
    "48": [
        44294.229166666664,
        90.0625
    ],
    "49": [
        51211.5625,
        92.1875
    ],
    "50": [
        44294.229166666664,
        90.0625
    ],
    "51": [
        51211.5625,
        92.1875
    ],
    "52": [
        44294.229166666664,
        90.0625
    ],
    "53": [
        51211.5625,
        92.1875
    ],
    "54": [
        44294.229166666664,
        90.0625
    ],
    "55": [
        51211.5625,
        92.1875
    ],
    "56": [
        44294.229166666664,
        90.0625
    ],
    "57": [
        51211.5625,
        92.1875
    ],
    "58": [
        44294.229166666664,
        90.0625
    ],
    "59": [
        51211.5625,
        92.1875
    ],
    "60": [
        44294.229166666664,
        90.0625
    ],
    "tie": [
        30616.375,
        2301.8125
    ],
    "black_wins": [
        30616.375,
        2301.8125
    ],
    "white_wins": [
        30616.375,
        2301.8125
    ],
    "you_have_won": [
        30616.375,
        2301.8125
    ],
    "you_have_lost": [
        30616.375,
        2301.8125
    ],
    "game_started": [
        35669.645833333336,
        1322.7708333333333
    ],
    "2_periods_left": [
        29406.375,
        210.0
    ],
    "3_periods_left": [
        29406.375,
        210.0
    ],
    "4_periods_left": [
        29406.375,
        210.0
    ],
    "5_periods_left": [
        29406.375,
        210.0
    ],
    "last_period": [
        29406.375,
        210.0
    ],
    "byoyomi": [
        29406.375,
        210.0
    ],
    "overtime": [
        29406.375,
        210.0
    ],
    "start_counting": [
        29406.375,
        210.0
    ],
    "your_opponent_has_disconnected": [
        23905.5,
        900.8541666666666
    ],
    "your_opponent_has_reconnected": [
        33918.1875,
        751.4583333333334
    ],
    "player_disconnected": [
        23905.5,
        900.8541666666666
    ],
    "player_reconnected": [
        33918.1875,
        751.4583333333334
    ],
    "undo_granted": [
        33918.1875,
        751.4583333333334
    ],
    "game_paused": [
        52303.75,
        626.9375
    ],
    "game_resumed": [
        52303.75,
        626.9375
    ],
    "remove_the_dead_stones": [
        29406.375,
        210.0
    ],
    "black-1": [
        22843.833333333332,
        61.66666666666667
    ],
    "black-2": [
        22843.833333333332,
        61.66666666666667
    ],
    "black-3": [
        22843.833333333332,
        61.66666666666667
    ],
    "black-4": [
        22843.833333333332,
        61.66666666666667
    ],
    "black-5": [
        22843.833333333332,
        61.66666666666667
    ],
    "white-1": [
        22843.833333333332,
        61.66666666666667
    ],
    "white-2": [
        22843.833333333332,
        61.66666666666667
    ],
    "white-3": [
        22843.833333333332,
        61.66666666666667
    ],
    "white-4": [
        22843.833333333332,
        61.66666666666667
    ],
    "white-5": [
        22843.833333333332,
        61.66666666666667
    ],
    "review_started": [
        57110.104166666664,
        2548.395833333333
    ],
    "tournament_starting": [
        57110.104166666664,
        2548.395833333333
    ]
}
};

sprite_packs["zz-un-floor-goban-rich"] = {
    pack_id: "zz-un-floor-goban-rich",
    language: "zz",
    country: "un",
    name: "Floor Goban (room)",
    filename_prefix: "zz-un-floor-goban-rich.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "black-1": [
        1000.0,
        622.8125
    ],
    "black-2": [
        2622.8125,
        659.8541666666666
    ],
    "black-3": [
        4282.666666666667,
        583.0208333333334
    ],
    "black-4": [
        5865.6875,
        476.70833333333337
    ],
    "black-5": [
        7342.395833333334,
        623.1875
    ],
    "white-1": [
        8965.583333333332,
        572.0625
    ],
    "white-2": [
        10537.645833333332,
        603.9791666666666
    ],
    "white-3": [
        12141.625,
        451.75
    ],
    "white-4": [
        13593.375,
        715.5208333333334
    ],
    "white-5": [
        15308.895833333332,
        679.1041666666666
    ]
}
};

sprite_packs["zz-un-floor-goban"] = {
    pack_id: "zz-un-floor-goban",
    language: "zz",
    country: "un",
    name: "Floor Goban",
    filename_prefix: "zz-un-floor-goban.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "black-1": [
        1000.0,
        137.20833333333331
    ],
    "black-2": [
        2137.208333333333,
        58.854166666666664
    ],
    "black-3": [
        3196.0625,
        86.60416666666666
    ],
    "black-4": [
        4282.666666666667,
        85.0
    ],
    "black-5": [
        5367.666666666667,
        86.8125
    ],
    "white-1": [
        1000.0,
        137.20833333333331
    ],
    "white-2": [
        2137.208333333333,
        58.854166666666664
    ],
    "white-3": [
        3196.0625,
        86.60416666666666
    ],
    "white-4": [
        4282.666666666667,
        85.0
    ],
    "white-5": [
        5367.666666666667,
        86.8125
    ]
}
};

sprite_packs["zz-un-melamine"] = {
    pack_id: "zz-un-melamine",
    language: "zz",
    country: "un",
    name: "Melamine",
    filename_prefix: "zz-un-melamine.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "black-1": [
        1000.0,
        55.0
    ],
    "black-2": [
        2055.0,
        82.52083333333333
    ],
    "black-3": [
        3137.5208333333335,
        64.04166666666666
    ],
    "black-4": [
        4201.5625,
        45.0
    ],
    "black-5": [
        5246.5625,
        46.79166666666667
    ],
    "white-1": [
        1000.0,
        55.0
    ],
    "white-2": [
        2055.0,
        82.52083333333333
    ],
    "white-3": [
        3137.5208333333335,
        64.04166666666666
    ],
    "white-4": [
        4201.5625,
        45.0
    ],
    "white-5": [
        5246.5625,
        46.79166666666667
    ]
}
};

sprite_packs["zz-un-original-2013"] = {
    pack_id: "zz-un-original-2013",
    language: "zz",
    country: "un",
    name: "Original (2013)",
    filename_prefix: "zz-un-original-2013.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "black-1": [
        1000.0,
        178.5
    ],
    "black-2": [
        2178.5,
        230.25
    ],
    "black-3": [
        3408.75,
        229.27083333333334
    ],
    "black-4": [
        4638.020833333333,
        236.66666666666666
    ],
    "black-5": [
        5874.6875,
        228.0
    ],
    "white-1": [
        1000.0,
        178.5
    ],
    "white-2": [
        2178.5,
        230.25
    ],
    "white-3": [
        3408.75,
        229.27083333333334
    ],
    "white-4": [
        4638.020833333333,
        236.66666666666666
    ],
    "white-5": [
        5874.6875,
        228.0
    ]
}
};

sprite_packs["zz-un-table-goban"] = {
    pack_id: "zz-un-table-goban",
    language: "zz",
    country: "un",
    name: "Table",
    filename_prefix: "zz-un-table-goban.v5",
    definitions: {
    "silence": [
        0,
        50
    ],
    "black-1": [
        1000.0,
        60.0
    ],
    "black-2": [
        2060.0,
        45.72916666666667
    ],
    "black-3": [
        3105.729166666667,
        62.875
    ],
    "black-4": [
        4168.604166666666,
        80.70833333333334
    ],
    "black-5": [
        5249.3125,
        80.0
    ],
    "white-1": [
        1000.0,
        60.0
    ],
    "white-2": [
        2060.0,
        45.72916666666667
    ],
    "white-3": [
        3105.729166666667,
        62.875
    ],
    "white-4": [
        4168.604166666666,
        80.70833333333334
    ],
    "white-5": [
        5249.3125,
        80.0
    ]
}
};
